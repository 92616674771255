<template>
  <v-select
    hide-details
    item-text="name"
    :items="items"
    :menu-props="{ 'offset-y': true, closeOnClick: true }"
    v-model="selectedItem"
  ></v-select>
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  fieldset {
    border-radius: 16px;
  }
}
</style>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    defaultValue: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    selectedItem: {
      get() {
        if (!this.value) return this.defaultValue;
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  }
};
</script>
