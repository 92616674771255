<template>
  <div class="d-flex align-center">
    <v-text-field
      ref="textfield"
      :label="label"
      :rules="[required, validEmail]"
      v-model="_value"
    />

    <v-btn
      v-if="showDeleteBtn && !showAddBtn"
      icon
      class="ml-1"
      @click="$emit('click:delete')"
    >
      <v-icon>mdi-minus</v-icon>
    </v-btn>
    <v-btn v-if="showAddBtn" icon class="ml-1" @click="$emit('click:add')">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  fieldset {
    border-radius: 16px;
  }
  .v-input__slot {
    margin-bottom: 0px;
  }
  .v-text-field__details {
    font-weight: bold;
    position: absolute;
    padding: 0px 4px;
    top: -4px;
    left: 8px;
  }
  .v-label {
    background: #fff;
  }

  &.error--text {
    .v-text-field__details {
      background: #fff;
    }

    .v-label {
      display: none !important;
    }
  }
}
</style>

<script>
import { isBlank, isEmail } from "@/commons/utils/validation";

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    showDeleteBtn: {
      type: Boolean,
      default: false
    },
    showAddBtn: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isEmail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _value: {
      get() {
        if (typeof this.value === "object") return this.value.value;
        return this.value;
      },
      set(_value) {
        if (typeof this.value === "object") return (this.value.value = _value);
        this.$emit("update:value", _value);
      }
    }
  },
  methods: {
    required(value) {
      if (!this.isRequired) return true;
      return !isBlank(value) || `${this.label} 값은 필수 값 입니다.`;
    },
    validEmail(value) {
      if (!this.isEmail) return true;
      return !!isEmail(value) || "e-mail 형식이 아닙니다.";
    }
  }
};
</script>
