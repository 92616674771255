<template>
  <div v-if="!isEdit" :class="{ 'mb-2': !lastItem }">
    <div class="d-flex align-center">
      <v-chip small label class="px-2 mr-1">{{ types[address.type] }}</v-chip>
      {{ address.post }}
      {{ address.locality }}
    </div>
    <div>
      {{ address.streetAddress }}
    </div>
  </div>

  <div v-else :class="{ 'mb-2': !lastItem }">
    <div class="d-flex align-center">
      <div class="cr-select">
        <Select :items="typeList" defaultValue="work" :value.sync="_type" />
      </div>
      <div class="cr-post">
        <TextField
          ref="post"
          :label="$t('contact.우편번호')"
          :value.sync="_post"
        />
      </div>
      <div class="d-flex flex-grow-1">
        <TextField
          :label="$t('contact.시도')"
          class="flex-grow-1"
          :value.sync="_locality"
        />
      </div>
    </div>
    <div class="mt-1">
      <TextField
        showDeleteBtn
        :showAddBtn="firstItem"
        :label="$t('contact.주소')"
        :value.sync="_streetAddress"
        @click:add="$emit('click:add')"
        @click:delete="$emit('click:delete')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-select {
  width: 130px;
  margin-right: 4px;
}
.cr-post {
  width: 180px;
  margin-right: 4px;
}
.cr-locality {
  width: 180px;
  margin-right: 4px;
}
</style>

<script>
import { mapGetters } from "vuex";
import Select from "@/contact/views/components/list/common/Select.vue";
import TextField from "@/contact/views/components/list/common/TextField.vue";

export default {
  components: { Select, TextField },
  props: {
    address: {
      type: Object,
      default: () => ({})
    },
    firstItem: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      types: { work: this.$t("contact.회사"), home: this.$t("contact.집") }
    };
  },
  computed: {
    ...mapGetters("customerCardDetail", ["isEdit"]),
    typeList() {
      const { types } = this;
      return Object.keys(types).map(value => ({ value, name: types[value] }));
    },
    _type: {
      get() {
        return this.address?.type || "work";
      },
      set(type) {
        const { post, locality, streetAddress } = this.address;
        this.$emit("update:value", { post, locality, streetAddress, type });
      }
    },
    _post: {
      get() {
        return this.address?.post || "";
      },
      set(post) {
        const { type, locality, streetAddress } = this.address;
        this.$emit("update:value", { post, locality, streetAddress, type });
      }
    },
    _locality: {
      get() {
        return this.address?.locality || "";
      },
      set(locality) {
        const { post, type, streetAddress } = this.address;
        this.$emit("update:value", { post, locality, streetAddress, type });
      }
    },
    _streetAddress: {
      get() {
        return this.address?.streetAddress || "";
      },
      set(streetAddress) {
        const { post, locality, type } = this.address;
        this.$emit("update:value", { post, locality, streetAddress, type });
      }
    }
  },
  methods: {
    focus() {
      this.$refs.post?.$refs?.textfield?.focus();
    }
  }
};
</script>
